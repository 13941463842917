import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Autocomplete, TextField } from '@mui/material';
import * as Sentry from '@sentry/react';
import { GET_AWOS } from '../queries';
import ErrorMessage from './ErrorMessage';
import { beforeCapture } from '../utilities';
import { Awo } from '../types/Awo';

const PHOTO_SMS_NAMES = ['Animal Shelter Manager', 'Animals First', 'Shelterluv'];
export type AutocompleteAwo = Pick<Awo, 'id' | 'name' | 'kittenFoster' | 'catFoster' | 'dogFoster' | 'catAdopt' | 'dogAdopt' | 'status' | 'smsName'>;

function AwoAutocomplete({
 selectedAwo, setAwo, showDeactivated, defaultName = 'Petcademy Admin', size = 'medium', onlyPhotoAwos = false, variant = 'outlined', shrink,
}: {
  selectedAwo: AutocompleteAwo | null,
  setAwo: React.Dispatch<React.SetStateAction<AutocompleteAwo | null>>,
  showDeactivated?: boolean,
  defaultName?: string,
  size?: 'medium' | 'small',
  onlyPhotoAwos?: boolean,
  variant?: 'outlined' | 'standard',
  shrink?: true | undefined
}) {
  const { loading, error, data } = useQuery<{ awos: AutocompleteAwo[] }>(GET_AWOS, {
    variables: {
      orderBy: 'name',
      order: 'asc',
    },
  });

  const filteredByStatus = useMemo(() => {
    if (!data?.awos) {
      return [];
    }
    if (onlyPhotoAwos) return data.awos.filter((awo) => awo.status !== 'Deactivated' && PHOTO_SMS_NAMES.includes(awo.smsName));
    if (showDeactivated) return data.awos;
    return data.awos.filter((awo) => awo.status !== 'Deactivated');
  }, [data, showDeactivated, onlyPhotoAwos]);

  const adminAwo: AutocompleteAwo = {
    id: null,
    name: defaultName,
    kittenFoster: true,
    catFoster: true,
    dogFoster: true,
    catAdopt: true,
    dogAdopt: true,
    status: 'Active',
    smsName: 'Shelterluv',
  };

  if (loading) return <span>Loading...</span>;
  if (error) throw error;
  return (
    <Autocomplete
      disablePortal
      id="awo-select"
      size={size}
      componentsProps={{
        popper: {
          placement: 'bottom',
          modifiers: [{
            name: 'flip',
            enabled: false,
          }],
        },
      }}
      options={[adminAwo].concat(filteredByStatus)}
      getOptionLabel={(option) => {
        if (!option.id) {
          return defaultName;
        }
        const deactivated = option.status === 'Deactivated' ? ' (Deactivated)' : '';
        return `${option.name}${deactivated}`;
      }}
      value={selectedAwo === null && defaultName !== 'Petcademy Admin' ? adminAwo : selectedAwo}
      onChange={(_, newValue) => {
        if (newValue !== null) {
          setAwo(newValue);
        }
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => <TextField {...params} variant={variant} InputLabelProps={{ shrink }} label="Organization" />}
    />
  );
}

export default Sentry.withErrorBoundary(AwoAutocomplete, { fallback: <ErrorMessage />, beforeCapture });
