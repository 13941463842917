import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import {
  Box, IconButton, InputAdornment, TextField, Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useIdentityContext } from 'react-netlify-identity-gotrue';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import SidebarPage from '../SidebarPage';
import { CREATE_SIGN_IN } from '../../queries';

function Login() {
  const identity = useIdentityContext();
  const navigate = useNavigate();
  const location = useLocation();

  let errorText = null;
  if (location.state?.authError) {
    errorText = location.state.authError;
  }

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [error, setError] = React.useState(errorText);
  const [makingRequest, setMakingRequest] = React.useState(false);
  const [createSignIn] = useMutation(CREATE_SIGN_IN);

  useEffect(() => {
    if (identity.user) {
      navigate(get(location, 'state.comingFrom', '/dashboard'), { replace: true, state: { authError: undefined, comingFrom: undefined } });
    }
  }, [identity.user, navigate, location]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMakingRequest(true);
    await identity.login({ email, password }).catch((e) => {
      console.log(e);
      setError('Something went wrong. Refresh your browser and try again.');
    });
    createSignIn({
      variables: {
        email,
      },
    });
    setMakingRequest(false);
  };

  return (
    <SidebarPage
      mobileHeader="Login"
      greenWord="Login"
      headerText=" to your Petcademy account"
      handleSubmit={handleSubmit}
      FormComponent={(
        <>
          <Box sx={{
            width: {
              xs: '100%',
              sm: '327px',
            },
          }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              helperText="Your email address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {error ? (
              <Typography variant="caption" sx={{ lineHeight: '1rem', mt: '0.25rem', display: 'inline-block' }} color="error.main">
                {error}
              </Typography>
            ) : null}
          </Box>
          <Button
            type="submit"
            variant="contained"
            disabled={email.length === 0 || password.length === 0 || makingRequest}
            sx={{ mt: 3, mb: 2, mr: 2 }}
          >
            Login
          </Button>
          <Button
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            href="/forgot"
          >
            Forgot Password
          </Button>
        </>
      )}
    />
  );
}

export default Login;
